import styled from 'styled-components';

import circleBg from '@common/src/assets/image/produkKami/kontak-kami-bg.png';
import { rgba } from 'polished';

const SectionWrapper = styled.div`
  margin-top: 5%;
  padding: 75px 0;
  @media only screen and (max-width: 1366px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 667px) {
    margin-top: 5%;
    padding: 45px 0;
  }
  > div.container {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const CarouseWrapper = styled.div`
  width: calc(100% - 428px);
  background-image: url(${circleBg});
  background-repeat: no-repeat;
  background-position: center center;
  @media only screen and (max-width: 1366px) {
    background-size: contain;
    align-self: center;
  }
  @media only screen and (max-width: 991px) {
    width: calc(100% - 350px);
  }
  @media only screen and (max-width: 667px) {
    margin-top: 10%;
    width: 100%;
    align-self: flex-start;
  }
  .glide {
    width: 337px;
    margin: 0 auto;
    position: relative;
    @media only screen and (max-width: 991px) {
      width: 310px;
    }
    @media only screen and (max-width: 667px) {
      width: 260px;
    }
    .glide__slide {
      img {
        display: inline-block;
      }
    }
    .glide__bullets {
      display: flex;
      width: 4px;
      flex-direction: column;
      position: absolute;
      top: calc(50% - 50px);
      left: -21px;
      > button.glide__bullet {
        width: 4px;
        height: 20px;
        border-radius: 5px;
        margin-left: 0;
        margin-right: 0;
        background-color: #FFADAD;
        transition: height 0.3s ease;
        &.glide__bullet--active {
          height: 35px;
          background-color: #b94a49;
        }
      }
    }
  }
`;

export const TextWrapper = styled.div`
  width: 428px;
  align-self: center;
  @media only screen and (max-width: 1366px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: 991px) {
    width: 350px;
    padding-left: 20px;
  }
  @media only screen and (max-width: 667px) {
    width: 100%;
    padding-left: 0;
    margin-top: 40px;
  }
  h2 {
    color: #0f2137;
    font-size: 32px;
    line-height: 44px;
    font-weight: 700;
    margin-bottom: 20px;
    @media only screen and (max-width: 1366px) {
      font-size: 30px;
      line-height: 42px;
      margin-bottom: 15px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 26px;
      line-height: 38px;
      margin-bottom: 20px;
    }
  }
  p {
    color: #0f2137;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
  }
  p:hover {
    color: #b94a49;
  }
  > p {
    margin-bottom: 20px;
  }
  .phone {
    text-align: left !important;
    margin-bottom: 0 !important;
    font-weight: 600 !important;
    font-size: 32px;
    align-self: center;
    @media only screen and (max-width: 1366px) {
      font-size: 28px;
      line-height: 42px;
      margin-bottom: 15px;
    }
    @media only screen and (max-width: 376px) {
      font-size: 20px;
      line-height: 38px;
      margin-bottom: 20px;
    }
  }
  .bisnis-title {
    margin-top: 10%;
  }
  .e-wrapper {
    margin-top: 5%;
  }
  .feature__block {
    margin-top: 30px;
    h3 {
      color: #0f2137;
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 9px;
      @media only screen and (max-width: 1366px) {
        line-height: 26px;
        margin-bottom: 7px;
      }
    }
    i {
      color: #b94a49;
      font-size: 18px;
      margin-right: 22px;
      
    }
  }
`;

export const ContactWrapper = styled.div`
  display: flex;
  margin-top: 5%;
  margin-bottom: 5%;
  align-self: center;
  > .socials-icon {
    align-self: center;
    margin-right: 5%;
    max-width: 50px;
    padding: 6px;
    border-radius: 30%;
    align-self: center;
    background-color: rgba(212, 98, 97, 0.1);
    color: rgba(212, 98, 97, 1);
    &:hover {
      color: ${rgba("#02073E", 0.8)};
    }
  }
  > .ig {
    margin-right: 4% !important;
  }
  > .cr {
    width: 75px;
  }
`;

export default SectionWrapper;
