import React from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { theme } from "@common/src/theme/agencyModern";
import { ResetCSS } from "@common/src/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "../components/styling-wrappers/agencyModern.style";
import { DrawerProvider } from "@common/src/contexts/DrawerContext";
import Navbar from "../components/public-components/Navbar";
import Kontak from "../components/kontak-kami-components/AppSlider";
import Form from "../components/kontak-kami-components/Subscribe";
import Footer from "../components/public-components/Footer";
import Seo from "../components/seo";
import PopupImage from "../components/PopupImage";
import ButtonContact from "../components/index-components/ButtonContact";
import Layout from "../components/layout";

const kontakPage = () => {
  return (
    <ThemeProvider theme={theme}>
        <Seo
          title="Kontak"
          description="GENME membantu Anda memberikan solusi yang personal dan lebih efektif berdasarkan DNA Anda, apa pun tujuan Anda, GENME dapat mempersonalisasikan jalan Anda menuju kehidupan yang lebih sehat dan bahagia."
        />
        <ResetCSS />
        <GlobalStyle />
        <Layout>
          <PopupImage/>
          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar origin="Kontak" />
              </DrawerProvider>
            </Sticky>
            <Kontak />
            <Form />
            <Footer />
            <ButtonContact/>
          </ContentWrapper>
      </Layout>
    </ThemeProvider>
  );
};

export default kontakPage;
