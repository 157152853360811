import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";

import Container from "@common/src/components/UI/Container";
import Heading from "@common/src/components/Heading";
import Button from "@common/src/components/Button";
import Text from "@common/src/components/Text";
import Alert from "@common/src/components/Alert";

import MailchimpSubscribe from "react-mailchimp-subscribe";
import InputField from "@common/src/components/Input/input.style";

import SectionWrapper, {
  FooterInner,
  Content,
  SubscriptionForm,
} from "./subscribe.style";

const CustomForm = ({ status, onValidated }) => {
  const [nama, setNama] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [nomortelpon, setNomorTelpon] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    nama &&
      email &&
      message &&
      nomortelpon &&
      email.indexOf("@") > -1 &&
      onValidated({
        MERGE1: nama,
        MERGE0: email,
        MERGE2: message,
        MERGE3: nomortelpon,
      });
  };

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const clearFields = () => {
    setNama("");
    setEmail("");
    setNomorTelpon("");
    setMessage("");
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <InputField>
        <div className="field-wrapper">
          <input
            type="text"
            placeholder="Name"
            name="MERGE1"
            id="MERGE1"
            onChange={(e) => {
              setNama(e.target.value);
            }}
            value={nama}
            required
          />
          <input
            type="email"
            placeholder="E-mail"
            name="MERGE0"
            id="MERGE0"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            required
          />
          <input
            type="tel"
            placeholder="Phone Number"
            name="MERGE3"
            id="MERGE3"
            onChange={(e) => {
              setNomorTelpon(e.target.value);
            }}
            value={nomortelpon}
            pattern="+[0-9]{10,14}"
            required
          />
          <textarea
            type="text"
            placeholder="Message"
            name="MERGE2"
            id="MERGE2"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            value={message}
            cols={40}
            rows={5}
            required
          />
        </div>
      </InputField>
      <Button
        // title="Send Message"
        title = {status === "sending" ? "Sending" : "Send Message"}
        disabled = {status === "sending"}
        type="submit"
        formValues={[nama, email, nomortelpon, message]}
      />
      {/* {status === "sending" && (
        <Alert className="alert-sending">Mengirim...</Alert>
      )} */}
      {status === "error" && (
        <Alert className="alert-error">
          Anda telah mengikuti kami atau terjadi kesalahan.
        </Alert>
      )}
      {status === "success" && (
        <Alert className="alert-success">
          Terima kasih telah mengikuti kami!
        </Alert>
      )}
    </form>
  );
};

const Subscribe = () => {
  const url = `https://gmail.us20.list-manage.com/subscribe/post?u=13e7c7bf92a68e7d89dd360c6&amp;id=b20351cc67`;
  return (
    <SectionWrapper id="kontak-subscribe">
      <Container>
        <FooterInner>
          <Fade left delay={100}>
            <Content>
              <Heading as="h3" content="Contact Us" />
            </Content>
          </Fade>
          <Fade right delay={100}>
            <SubscriptionForm>
              <MailchimpSubscribe
                url={url}
                render={({ subscribe, status }) => (
                  <CustomForm
                    status={status}
                    onValidated={(formData) => subscribe(formData)}
                  />
                )}
              />
            </SubscriptionForm>
          </Fade>
        </FooterInner>
      </Container>
    </SectionWrapper>
  );
};

export default Subscribe;
