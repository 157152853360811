import React, { Fragment } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Text from "@common/src/components/Text";
import Image from "gatsby-image";
import ComImage from "@common/src/components/Image";
import Heading from "@common/src/components/Heading";
import Container from "@common/src/components/UI/Container";
import GlideCarousel from "@common/src/components/GlideCarousel";
import GlideSlide from "@common/src/components/GlideCarousel/glideSlide";
import Fade from "react-reveal/Fade";
import SectionWrapper, {
  CarouseWrapper,
  TextWrapper,
  ContactWrapper,
} from "./appSlider.style";
import Icon from "react-icons-kit";
import { whatsapp } from "react-icons-kit/icomoon/whatsapp";
import { ic_mail_outline } from "react-icons-kit/md/ic_mail_outline";
import { instagram } from "react-icons-kit/fa/instagram";
import { youtube } from "react-icons-kit/icomoon/youtube";
import { twitter } from "react-icons-kit/icomoon/twitter";
import { FaTiktok } from "react-icons/fa";
import { facebook } from "react-icons-kit/icomoon/facebook";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
//import tiktok from "@common/src/assets/image/kontakKami/SVG/tiktok.svg";
import Layanan from "../Clients";

const AppSlider = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulKontakKami(sort: { order: ASC, fields: createdAt }) {
        nodes {
          id
          title
          image {
            gatsbyImageData(quality: 100)
          }
          url
        }
      }
      nutrigenme: contentfulProdukKami(title: { eq: "NutriGEN-ME" }) {
        image {
          gatsbyImageData(width: 180, quality: 100)
        }
      }
      skingenme: contentfulProdukKami(title: { eq: "SkinGEN-ME" }) {
        image {
          gatsbyImageData(width: 175, quality: 100)
        }
      }
      fitgenme: contentfulProdukKami(title: { eq: "FitGEN-ME" }) {
        image {
          gatsbyImageData(width: 175, quality: 100)
        }
      }
      genmeLogo: contentfulAsset(contentful_id: {eq: "46FGc0qpR43FtHPv1QBd3o"}) {
        gatsbyImageData(height: 64, quality: 100)
      }
      kalbeGenme: contentfulAsset(contentful_id: {eq: "6TbxFTkD0K2bUjuFxNuEbx"}) {
        gatsbyImageData(height: 64, quality: 100)
      }
      nutrigenmeLIFE: contentfulProdukKami(title: { eq: "NutriGEN-ME LIFE" }) {
        image {
          gatsbyImageData(width: 175, quality: 100)
        }
      }
      kiddygenme: contentfulProdukKami(title: { eq: "KiddyGEN-ME" }) {
        image {
          gatsbyImageData(width: 175, quality: 100)
        }
      }
      primegenme: contentfulProdukKami(title: { eq: "PrimeGEN-ME" }) {
        image {
          gatsbyImageData(width: 175, quality: 100)
        }
      }
      crIcon: file(relativePath: { eq: "image/logo-cr-genme.png" }) {
        childImageSharp {
          fluid(maxWidth: 1170, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const glideOptions = {
    type: "carousel",
    gap: 0,
    autoplay: 5000,
    perView: 1,
    animationDuration: 700,
  };
  // const nutrigenme = data.nutrigenme.image.gatsbyImageData;
  // const skingenme = data.skingenme.image.gatsbyImageData;
  // const fitgenme = data.fitgenme.image.gatsbyImageData;
  // const nutrigenmeLIFE = data.nutrigenmeLIFE.image.gatsbyImageData;
  // const primegenme = data.primegenme.image.gatsbyImageData;
  // const kiddygenme = data.kiddygenme.image.gatsbyImageData;

  const genmeLogo = data.genmeLogo.gatsbyImageData;
  const kalbeGenme = data.kalbeGenme.gatsbyImageData

  const crIcon = data.crIcon.childImageSharp.fluid;

  return (
    <SectionWrapper>
      <Container>
        <CarouseWrapper>
          <GlideCarousel
            bullets={true}
            controls={false}
            numberOfBullets={5}
            options={glideOptions}
            carouselSelector="appFeatureSlider"
          >
            <Fragment>
              {data.allContentfulKontakKami.nodes.map((item) => (
                <GlideSlide key={`feature-side--key${item.id}`}>
                  <Link to={`${item.url}`}>
                    <GatsbyImage
                      image={item.image.gatsbyImageData}
                      alt={item.title}
                    />
                  </Link>
                </GlideSlide>
              ))}
            </Fragment>
          </GlideCarousel>
        </CarouseWrapper>
        <Fade right delay={100}>
          <TextWrapper>
            <Heading as="h2" content="Temukan Kami di Berbagai Platform" />
            <Link href="https://wa.me/+6285281112343?text=Halo%20GENME%20%21%0AMau%20tanya%20info%20mengenai%20tes%20genetik%20lebih%20lanjut.%20Terima%20kasih" target="_blank">
              <ContactWrapper>
                <Icon
                  icon={whatsapp}
                  className="socials-icon whatsapp"
                  size={16}
                />
                <Text className="phone" content="0852 8111 2343" />
                <Image fluid={crIcon} className="cr" />
              </ContactWrapper>
            </Link>
            <ContactWrapper>
              <Icon icon={instagram} className="socials-icon ig" size={16} />
              <Link
                to={`https://www.instagram.com/genmebykalbe/`}
                target="_blank"
              >
                <GatsbyImage image={getImage(genmeLogo)} />
              </Link>
              <Link
                to={`https://www.instagram.com/nutrigenme/`}
                target="_blank"
              >
                <GatsbyImage image={getImage(kalbeGenme)} />
              </Link>
              {/* <Link to={`https://www.instagram.com/skingenme/`} target="_blank">
                <GatsbyImage image={getImage(skingenme)} />
              </Link> */}
              {/* <Link to={`https://www.instagram.com/fitgenme/`} target="_blank">
                <GatsbyImage image={getImage(nutrigenmeLIFE)} />
              </Link> */}
              {/* <Link
                to={`https://www.instagram.com/kiddygenme/`}
                target="_blank"
              >
                <GatsbyImage image={getImage(kiddygenme)} />
              </Link>
              <Link
                to={`https://www.instagram.com/primegenme/`}
                target="_blank"
              >
                <GatsbyImage image={getImage(primegenme)} />
              </Link> */}
            </ContactWrapper>
            {/* <Link
              to={`https://www.facebook.com/people/Genme-Kalbe/100071847288771/?sk=photos`}
              target="_blank"
            >
              <ContactWrapper>
                <Icon
                  icon={facebook}
                  className="socials-icon facebook"
                  size={16}
                />
                <Text content="GENME by Kalbe" />
              </ContactWrapper>
            </Link> */}
            <Link
              to={`https://www.youtube.com/channel/UCRSpTcG4iLGJeg2xamDHAbA`}
              target="_blank"
            >
              <ContactWrapper>
                <Icon
                  icon={youtube}
                  className="socials-icon youtube"
                  size={16}
                />
                <Text content="GENME by Kalbe" />
              </ContactWrapper>
            </Link>
            <Link to={`https://twitter.com/genmebykalbe/`} target="_blank">
              <ContactWrapper>
                <Icon
                  icon={twitter}
                  className="socials-icon twitter"
                  size={16}
                />
                <Text content="@genmebykalbe" />
              </ContactWrapper>
            </Link>
            <Link to={`https://vt.tiktok.com/ZSeya9QAv/`} target="_blank">
              <ContactWrapper>
                <FaTiktok 
                  className="socials-icon tiktok" 
                  size={28}
                  />
                <Text content="@genmebykalbe" />
              </ContactWrapper>
            </Link>
            <Layanan className="e-wrapper" />
            <Heading
              content="Untuk Kebutuhan Bisnis"
              className="bisnis-title"
            />
            <a href="mailto:info@genme.id" target="_blank">
              <ContactWrapper>
                <Icon
                  icon={ic_mail_outline}
                  className="socials-icon email"
                  size={16}
                />
                <Text content="info@genme.id" />
              </ContactWrapper>
            </a>
          </TextWrapper>
        </Fade>
      </Container>
    </SectionWrapper>
  );
};

export default AppSlider;
