import styled from 'styled-components';

export const ClientsImage = styled.div`
  padding: 0 15% 0 0;
  align-self: center;
  &:hover {
    img {
      filter: grayscale(0);
      opacity: 1;
    }
  }
  img {
    filter: grayscale(1);
    opacity: 0.5;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }
`;

export const TextWrapper = styled.div`
  h2 {
    color: #0f2137;
    font-size: 32px;
    line-height: 44px;
    font-weight: 700;
    margin-bottom: 20px;
    @media only screen and (max-width: 1366px) {
      font-size: 30px;
      line-height: 42px;
      margin-bottom: 15px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 26px;
      line-height: 38px;
      margin-bottom: 20px;
    }
  }
`;
