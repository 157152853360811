import styled from 'styled-components';
import { rgba } from 'polished';

const SectionWrapper = styled.section`
  background-color: #f6eee8;
  padding: 75px 0;
  position: relative;
  z-index: 0;
  width: 100%;
  .illustration {
    position: absolute;
    z-index: -1;
  }
  .bg1 {
    top: 170px;
    left: 300px;
  }
  .bg2 {
    bottom: 0;
    right: 20px;
  }
  .bg3 {
    left: 0;
    top: 0;
  }
  .bg4 {
    right: 280px;
    top: 0;
  }
  .bg5 {
    left: 140px;
    bottom: 0;
  }
`;

export const FooterInner = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  > div {
    width: calc(80%);
    @media only screen and (max-width: 1023px) {
      width: 80%;
    }
  }
`;

export const Content = styled.div`
  @media only screen and (max-width: 1023px) {
    margin-right: 0;
  }
  width: 100%;
  h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 55px;
    letter-spacing: -0.5px;
    margin-bottom: 6px;
    color: #d46261;
    text-align: center;
    @media only screen and (max-width: 600px) {
      font-size: 24px;
      line-height: 35px;
    }
  }
  p {
    max-width: 300px;
    font-size: 16px;
    line-height: 30px;
    color: #0f2137;
  }
`;

export const SubscriptionForm = styled.div`
  > div {
    display: flex;
    justify-content: flex-end;
  }
  .field-wrapper {
    input {
      background-color: #eff3f7;
      border-radius: 8px;
      font-family: 'DM Sans', sans-serif;
      font-size: 16px;
      margin-bottom: 3%;
      min-height: 30px;
      padding: 0 24px;
      ::placeholder {
        color: ${rgba('#02073E', 0.4)};
        opacity: 1; /* Firefox */
      }
      &:focus {
        border-color: #ff825c;
      }
      @media only screen and (max-width: 480px) {
        min-height: 50px;
      }
    }
    textarea {
      background-color: #eff3f7;
      padding: 10px 24px;
      font-family: 'DM Sans', sans-serif;
      border-radius: 8px;
      font-size: 16px;
      resize: none;
      ::placeholder {
        color: ${rgba('#02073E', 0.4)};
        opacity: 1; /* Firefox */
      }
      &:focus {
        border-color: #ff825c;
      }
    }
  }
  button {
    background-color: #d46261;
    min-width: 100%;
    min-height: 30px;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius: 15px;
    @media only screen and (max-width: 480px) {
      min-width: 100px;
    }
    &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  }
  .reusecore__checkbox {
    margin-top: 10px;
    .reusecore__field-label {
      cursor: pointer;
      color: ${rgba('#9095ad', 0.9)};
      font-weight: 400;
      font-size: 14px;
    }
    .checkbox + div {
      background-color: #eff3f7;
      border: 0;
      &::after {
        top: 2px;
      }
    }
  }
  .alert-sending {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
  }
  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
`;

export default SectionWrapper;
