import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import { ClientsImage } from "./clients.style";
import { ContactWrapper } from "../AppSlider/appSlider.style";
import { GatsbyImage } from "gatsby-plugin-image";
import Icon from "react-icons-kit";
import { shoppingCart } from "react-icons-kit/fa/shoppingCart";

const ClientsSection = () => {
  const Data = useStaticQuery(graphql`
    query {
      allContentfulECommerce(sort: { fields: contentful_id, order: DESC }) {
        nodes {
          id
          title
          image {
            gatsbyImageData(height: 35, quality: 100)
          }
          url
        }
      }
    }
  `);

  return (
    <ContactWrapper>
      <Icon icon={shoppingCart} className="socials-icon" size={16} />
      {Data.allContentfulECommerce.nodes.map((item, index) => (
        <Link to={`${item.url}`} target="_blank">
          <ClientsImage key={`client-${index}`}>
            <GatsbyImage image={item.image.gatsbyImageData} alt={item.title} />
          </ClientsImage>
        </Link>
      ))}
    </ContactWrapper>
  );
};

ClientsSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  row: PropTypes.object,
};

ClientsSection.defaultProps = {
  sectionWrapper: {
    pt: ["40px", "60px", "80px", "80px", "80px"],
    pb: ["60px", "80px", "100px", "130px", "130px"],
  },
  secTitleWrapper: {
    mb: "60px",
  },
  secTitle: {
    fontSize: ["22px", "26px", "26px", "30px", "30px"],
    fontWeight: "700",
    color: "#302b4e",
    lineHeight: "1.34",
    mb: ["15px", "18px", "18px", "20px", "20px"],
    textAlign: "center",
  },
  secDescription: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#43414e",
    lineHeight: "1.5",
    mb: "0",
    textAlign: "center",
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default ClientsSection;
